.select-group {
    position: relative;

    select {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid var(--body-border-color);
        background-color: var(--body-bg-color);
        -webkit-padding-end: var(--gutter-xlg);
        padding-inline-end: var(--gutter-xlg);
    }

    svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 1;
        color: var(--body-color);
        pointer-events: none;
    }
}


@hack: true; @import "/app/src/asset/theme.less";