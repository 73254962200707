.cart-page {
    margin-bottom: 60px;

    .cart-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .btn {
            padding: 14px var(--gutter-lg);
        }
    }

    .cart-form-wrapper {
        margin-top: 38px;
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: 768px) {
        .cart-form-wrapper {
            margin-top: 58px;
        }
    }


    @media (min-width: 576px) {
        .cart-btn-wrapper {

            .btn {
                &:not(:last-child) {
                    -webkit-margin-end: var(--gutter-xxs);
                    margin-inline-end: var();
                }
            }
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";