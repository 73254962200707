.header-search {
    max-width: 100%;
    flex: 1;
    padding: 0 15px;
    -webkit-margin-end: auto;
    margin-inline-end: auto;


    .ant-input-group-addon {
        border: 2px solid #fed700;
        border-radius: 25px;

        .ant-input-search-button {
            color: #2a455d;
            background-color: #fed700!important;
            border-start-end-radius: 25px!important;
            border-end-end-radius: 25px!important;
            padding-top: 6px!important;
        }
    }

    .ant-input-outlined {
        border-top: 2px solid #fed700!important;
        border-bottom: 2px solid #fed700!important;
        border-left-color: #d9d9d9!important;
        :hover {
            border-color: inherit!important;
            border-left-color: #d9d9d9!important;
        }
    }


}

@hack: true; @import "/app/src/asset/theme.less";