.product-page {
    .product-page-main {
        margin-bottom: 20px;
    }

    .grid-md-2 {
        grid-template-columns: 100%;
    }

    .layout-left {
        margin-bottom: 10px;
    }

    .layout-right {
        margin-left: 10px;
    }
}


@media (min-width: 768px) {
    .product-page {
        .grid-md-2 {
            grid-template-columns: 40% 60%;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";