.footer-content {
    padding: 60px 0 55px 0;

    .section-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        .block-widget {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 15px;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 700;
            }

            ul {
                margin-top: 25px;
                display: block;

                li {
                    list-style: none;
                    margin: 0 0 12px;
                    color: #333e48;
                }
            }
        }

        @media (min-width: 768px) {
            .block-widget {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        @media (min-width: 992px) {
            .block-widget {
                flex: 1;
                max-width: 33.33333%;
                min-width: 16.66667%;
            }
        }

    }
}

@hack: true; @import "/app/src/asset/theme.less";