.form-wrapper {
    padding: 0 0 30px;
    margin-bottom: 25px;
    border-bottom: 1px solid #dddddd;

    &:last-child {
        border: none;
    }

    .form-title {
        font-weight: 400;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 15px;
        padding-bottom: 10px;
        position: relative;
        margin-top: 0;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            width: 115px;
            height: 2px;
            background: #fed700;
        }
    }

    .register-benefit {
        padding-top: 0;
        padding-bottom: 20px;

        .benefit-list {
            -webkit-padding-start: 0;
            padding-inline-start: 0;

            li {
                list-style: none;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                   margin-bottom: 10px;
                }

                svg {
                    color: #5bb300;
                    flex: 0 0 14px;
                    margin: 0;
                    -webkit-margin-end: 5px;
                    margin-inline-end: 5px;

                    path {
                        fill: currentColor;
                    }
                }
            }
        }

    }

    .semi-bold {
        font-weight: 400;
        margin: 0 0 19px;
    }

    .account-form {
        .ant-form-item {
            //margin-bottom: 0;
        }

        .ant-form-item-label {
            padding: 0;
        }


        label {
            display: block;
            clear: both;
            margin-bottom: 10px;
            font-weight: 700;
            color: #333e48;
            font-size: 14px;

            &:before {
                display: none!important;
            }
        }

        .ant-form-item-required:after {
            visibility: visible;
            display: inline-block;
            margin-inline-end: 4px;
            //color: #ff4d4f;
            font-family: SimSun, sans-serif;
            font-size: 14px;
            line-height: 1;
            content: "*";
        }


        input {
            display: block;
            clear: both;
            //margin-bottom: 20px;
            height: 47px;

            border: 1px solid #dddddd;
            background-color: #f7f7f7;
            color: #333e48;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 25px;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 15px;
            font-family: inherit;
        }

        .ant-input-affix-wrapper {
            border: 1px solid #dddddd;
            border-radius: 25px;
            background-color: #f7f7f7;
            padding: 0;

            &:focus-within, &:hover {
                background-color: #f7f7f7!important;
            }

            input {
                margin: 0;
                border: none;
            }

            .ant-input-suffix {
                margin-inline-end: 15px;
            }
        }

        .btn {
            min-width: 150px;
            width: 100%;
            display: block;
            margin-bottom: 10px;
            line-height: 19px;
        }

        @media (min-width: 576px) {
            .btn {
                width: auto;
                display: inline-block;
            }
        }

        .btn-register {
            min-width: 150px;
        }
    }

}

@media (min-width: 768px) {
    .form-wrapper {
        padding: 0 var(--gutter-lg);
        margin-bottom: 0;
        border-bottom: none;
        border-inline-end-width: 1px;
        border-inline-end-style: solid;
        border-inline-end-color: #dddddd;
    }
}

@media (min-width: 992px) {
    .form-wrapper {
        padding: 0 65px;
    }
}




@hack: true; @import "/app/src/asset/theme.less";