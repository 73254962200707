.search-toolbar-wrapper {
    background: var(--second-bg-color);
    border-radius: 9px;
    padding: 7px var(--gutter-xxs);
    margin-bottom: var(--gutter-sm);
    min-height: 40px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    .search-sorting {
        display: flex;
        align-items: center;
        -webkit-margin-start: auto;
        margin-inline-start: auto;

        .ant-select {
            height: 39px;
        }

        .ant-select-focused {
            border: 1px solid var(--body-border-color);
        }

        .ant-select-selector {
            background: var(--body-bg-color);
            border: 1px solid var(--body-border-color);
            border-radius: 25px;

            &:active {
                border: inherit!important;
            }
        }
    }

    .collection-view-as {
        padding-top: 5px;
        padding-bottom: 5px;

        .grid-list {
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
                flex: 0 0 18px;
                margin: 0 5px;
                color: var(--body-color-2);
                cursor: pointer;
            }
        }
    }

    @media (min-width: 768px) {
        padding-left: var(--gutter-sm);
        padding-right: var(--gutter-sm);
    }

}

@hack: true; @import "/app/src/asset/theme.less";