.product_price-wrapper {
    display: flex;

    .product_price {
        display: flex;
        justify-content: flex-end;

        .price-range {
            width: fit-content;
            white-space: nowrap;
            margin-right: 24px;
            min-width: 110px;
            max-width: 170px;

            //margin-right: 20px;
            //border: 1px solid rgb(28, 69, 107);
            //border-right: 1px solid rgba(28, 69, 107, 0.19);

            div {
                //margin-right: 10px;
                //line-height: 1.2;
                text-align: center;
                border: none;
            }

            .price {
                font-size: 22px;
                color: var(--price-color);
                display: flex;
                justify-content: center;

                span {
                    margin: 0 10px;
                }

            }

            .amount {

            }
        }

        //:first-child {
        //    border-left: 1px solid rgba(28, 69, 107, 0.19);
        //}
    }
}

@hack: true; @import "/app/src/asset/theme.less";