.page-template {
    margin-top: 25px;
    margin-bottom: 30px;
}

.page-account {
    margin-top: 25px    ;
    margin-bottom: 40px;

    @media (min-width: 992px) {
        margin-bottom: 60px;
    }
}



@hack: true; @import "/app/src/asset/theme.less";