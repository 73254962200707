:root {
    min-height: 100vh;
    flex: 1;
    font-weight: 400;
    line-height: 1.3;
    margin: 0;
    padding: 0;

    //min-width: var(--min-page-width);
    width: 100%;
    box-sizing: border-box;

    --main-color: #fed700;
    --price-color: #343f49;

    --min-page-width: 768px;

    --font-body-weight: 400;
    --font-bold: 700;

    --heading-size-h1: 36px;
    --heading-size-h2: 30px;
    --heading-size-h3: 22px;
    --heading-size-h4: 20px;
    --heading-size-h5: 18px;
    --heading-size-h6: 16px;
    --heading-1-weight: 600;
    --heading-2-weight: 600;
    --heading-3-weight: 600;
    --heading-4-weight: 600;
    --heading-5-weight: 600;
    --heading-6-weight: 600;

    --topbar-bg-color: #f5f5f5;
    --topbar-text-color: #333e48;
    --topbar-text-color-rgba: 51, 62, 72;
    --header-bg-color: #ffffff;
    --text-header-color: #333e48;
    --text-header-color-rgba: 51, 62, 72;
    --header-text-color-hover: #fed700;

    --heading-font: Inter, sans-serif;

    --body-font-size: 14px;
    --body-border-color: #dddddd;
    --body-bg-color: #f7f7f7;
    --second-bg-color: #ececec;
    --body-color: #333e48;
    --body-color-rgba: 51, 62, 72;
    --body-link: #333e48;
    --body-link-hover: #fed700;
    --body-color-2: #878787;

    --btn2-fw: 700;
    --btn2-cl: #333e48;
    --btn2-bg-cl: #e6e6e6;
    --btn2-hover-cl: #333e48;
    --btn2-hover-bg-cl: #fed700;
    --btn2-border-width: 1px;
    --btn2-border-cl: #e6e6e6;
    --btn2-hover-border-cl: #fed700;
    --gutter-xlg: 40px;
    --gutter-lg: 30px;
    --gutter-md: 25px;
    --gutter-sm: 20px;
    --gutter-xs: 15px;
    --gutter-xxs: 10px;

    --header-bg-mobile-color: #FED700;
    --header-mobile-text-color: #333e48;
    --bg-color-mobile-number-icon: #333e48;
    --color-mobile-number-icon: #ffffff;

    --product-card-title-cl: #0062bd;
    --product-card-title-fs: 14px;
    --product-card-vendor-fs: 12px;
    --product-card-price-fs: 20px;
    --product-card-price-sale-fs: 20px;
    --product-card-price-compare-fs: 12px;


    h1, h2 {
        font-size: 30px;
    }

    h5 {
        font-size: 18px;
        font-weight: 600;
    }

    p {
        margin: 0 0 20px;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    img {
        max-width: 100%;
    }

    input, input:focus, select, select:focus, textarea, textarea:focus {
        outline: none;
        box-shadow: none;
    }

    input, select, textarea {
        border: 1px solid var(--body-border-color);
        background-color: var(--body-bg-color);
        color: var(--body-color);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 25px;
        font-size: var(--body-font-size);
        font-weight: var(--font-body-weight);
        padding: var(--gutter-xxs) var(--gutter-xs);
        font-family: var(--body-font);
    }

    .header-group {
        margin: 0;
        padding: 0;
    }

    .main-layout {
        //background-image: url('../../logo_gray2.png');
        //background-repeat: no-repeat;
        //background-position: center;
        //opacity: 1;
    }

    .main-layout-content {
        //margin-left: 20px;

        .breadcrumb {
            margin-top: 25px;
            padding: 10px 0;
        }
    }

    .footer {
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        background: #ffffff;
    }

    .btn {
        display: block;
        padding: 14px 10px;
        text-align: center;
        box-shadow: none;
        text-decoration: none;
        font-weight: 700;
        line-height: inherit;
        cursor: pointer;
        //font-family: var(--body-font);
        transition: background .25s ease-in-out, color .25s ease-in-out, border .25s ease-in-out;
        height: auto;
    }

    .btn-1 {
        font-size: 14px;
        font-weight: 700;
        color: #333e48;
        background: #fed700;
        border: 1px solid #fed700;
        border-radius: 25px;

        &:hover {
            text-decoration: none;
            color: #ffffff;
            background: #333e48 !important;
            border-color: #333e48 !important;
        }

        &:disabled {
            border-color: #d9d9d9;
            background: rgba(0, 0, 0, 0.04);
        }
    }

    .btn-2 {
        font-size: 14px;
        font-weight: 700;
        color: #333e48;
        background: #e6e6e6;
        border: 1px solid #e6e6e6;
        border-radius: 25px;

        &:hover {
            text-decoration: none;
            color: var(--btn2-hover-cl);
            background: var(--btn2-hover-bg-cl);
            border-color: var(--btn2-hover-border-cl);
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .d-flex {
        display: flex!important;
    }

    .align-items-center {
        align-items: center !important;
    }

    .justify-content-between {
        justify-content: space-between !important;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .d-grid {
        display: grid;
    }

    .grid-1 {
        grid-template-columns: repeat(1, 1fr);
    }

    .position-relative {
        position: relative !important;
    }

    .overlay-ui {
        position: absolute;
        left: 0;
        top: 0;
    }

    .upload-icon {
        cursor: pointer;
        transition: 0.3s;
    }

    .upload-icon:hover {
        transform: scale(1.1);
    }

}

@media (min-width: 768px) {
    :root {
        .grid-md-2 {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}






@hack: true; @import "/app/src/asset/theme.less";