.product-props-wrapper {
    .prop-item-wrapper {
        display: flex;
        flex: 1;

        h3 {
            font-size: 16px;
        }

        .prop-img-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex: 1;

            .prop-img {
                padding: 1px;
                display: flex;
                justify-content: flex-start;
                position: relative;
                margin-bottom: 10px;
                cursor: pointer;
                border: 1px solid rgb(217, 217, 217);
                align-items: center;
                margin-right: 5px;

                img {
                    width: 42px;
                    height: 42px;

                    margin-right: 2px;
                    border-radius: 2px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
            }

            .active {
                border: 1px solid #2a455d;
            }

            .prop-name {
                margin: 0 2px;

            }

        }

        .prop-data-wrapper {
            flex: 1 1 0;
            overflow: hidden;

            .sku-item-wrapper {
                display: grid;
                grid-template-columns: 2fr 1fr 1fr;
                gap: 4px;
                align-items: center;

                margin-bottom: 14px;
                min-height: 36px;
                border-bottom: 1px dashed #e9e9e9;
                padding-bottom: 14px;
                //padding-right: 9px;
                position: relative;

                div {
                    display: flex;
                    justify-content: flex-end;
                }

                .name {
                    justify-content: flex-start;

                    img {
                        width: 42px;
                        height: 42px;
                        border-radius: 2px;
                        overflow: hidden;
                        flex-shrink: 0;
                        margin-right: 5px;
                    }
                }

                .stock {
                    display: none;
                }

                .amount {
                    //justify-content: flex-end;
                }

            }
        }

    }

    .buttons-panel-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .btn-sku {
            min-width: 220px;
        }
    }

}

@hack: true; @import "/app/src/asset/theme.less";