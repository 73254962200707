.header-action-list {
    align-items: start;
    justify-content: flex-end;
    display: flex;
    padding-inline-start: 15px;
    margin-top: 7px;

    :not(:first-child) {
        margin-inline-start: 35px;
    }

    .cart {
        display: flex;

        .icon {
            position: relative;
            display: flex;
            flex: 0 0 50%;
        }

        .total-price {
            flex: 0 0 50%;
            margin-left: 4px;
        }
    }

    .header-action-item {
        color: rgb(51, 62, 72);
        fill: rgb(51, 62, 72);
        font-weight: 800;
        align-items: center;


        .icon {
            :hover {
                color: #fed700;
            }

        }

        div {
            margin: 0;
        }

    }
}

@hack: true; @import "/app/src/asset/theme.less";