.factory-viewer-wrapper {
    padding: 3px;
    font-family: 'Open Sans', arial, sans-serif;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 14px;
    min-width: 600px;
    max-width: 1600px;


    .bold {
        font-weight: bold;
    }

    .right {
        text-align: right;
    }


    .factory-content{
        flex-basis: 80%;

        .factory-caption {
            //height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            //font-family: Helvetica,Arial,sans-serif;
            margin-right: 20px;
            margin-bottom: 3px;

            a {
                color: rgb(25, 50, 73);
                font-weight: 600;
                font-size: 15px;

                &:first-letter {
                    text-transform: uppercase;
                }

                &:hover {
                    color: #33b0de;
                }
            }

        }

        .factory-product-tags {
            &:first-letter {
                text-transform: lowercase;
            }
        }

        .factory-detail-container {
            margin-left: 10px;
            min-width: 110px;
            margin-top: 5px;
            border-top: 1px solid rgba(28, 69, 107, 0.19);

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            &>div{
                h4 {
                    margin-top: 5px;
                    color: #a21254;
                    text-align: center;
                    font-size: 13px;
                }

                &>div {
                    margin-left: 10px;
                    border-right: 1px solid rgba(28, 69, 107, 0.19);
                    padding-right: 10px;

                    height: 80%;
                    overflow: hidden;
                    //min-height: 100%;

                    p {
                        font-size: 12px;
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }
                }
            }

        }
    }

    .factory-info {
        flex-basis: 20%;
        font-size: 12px;
        text-align: right;

    }
}

@hack: true; @import "/app/src/asset/theme.less";