.header-action-list .header-action-item {
    .number {
        position: absolute;
        left: 20px;
        bottom: -4px;
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fed700;
        color: #333e48;
        font-size: 12px;
        font-weight: 700;
        padding: 0 8px;

        &:hover {
            color: #333e48;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";