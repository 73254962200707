.order {
    margin-bottom: 20px;

    .header {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;

        .title {
            font-size: var(--heading-size-h4);
            font-weight: var(--heading-4-weight);
        }

        .order-number {
            font-size: var(--body-font-size);
            font-weight: var(--font-body-weight);
        }

        .total-container {
            div {
                display: flex;

                .total {
                    margin-left: 10px;
                    font-size: var(--heading-size-h6);
                    font-weight: var(--font-bold);
                    color: var(--text-header-color);
                }
            }

        }
    }

    .content {
        padding: 10px 15px;
        flex: 1;
        flex-wrap: wrap;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        border-bottom: 1px solid var(--body-border-color);

        .delivery-container {
            flex: 0 0 50%;

            .delivery {
                display: flex;

                .transport-company {
                    font-weight: var(--font-bold);
                    margin: 0 10px;
                }
            }
        }

        .products {
            flex: 0 0 50%;
            display: flex;
            justify-content: flex-end;
            overflow: hidden;

            .product {
                height: 84px;
                width: 84px;
                margin-left: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .status {
        font-weight: var(--font-bold);
        font-size: var(--body-font-size);
        color: #333e48;
        background: #fed700;
        border: 1px solid #fed700;
        border-radius: 15px;

        padding: 0 10px;
    }
}

@hack: true; @import "/app/src/asset/theme.less";