.star-rating {
    display: flex;
    gap: 3px;
    font-size: 20px;
}

.star {
    width: 20px;
    height: 20px;
}

.full {
    color: #FFD700; // Золотой цвет для полных звезд
}

.half {
    color: #FFD700; // Половинка тоже золотая
}

.empty {
    color: #ccc; // Серый цвет для пустых звезд
}

@hack: true; @import "/app/src/asset/theme.less";