.topbar-container {
    display: block;
    background: var(--topbar-bg-color);
    padding: 6px 0;

    .topbar-wrapper {
        line-height: 20px;
    }

    .topbar-left {
        .icon {
            color: #fed700;
        }
    }

    .topbar-items {
        display: flex;
        font-size: 14px;
        color: #333e48;

        li {
            padding: 0 15px;
            display: flex;
            align-items: center;

            :first-child {
                padding-inline-start: 0;
            }

            :last-child {
                padding-inline-end: 0;
            }

            .icon {
                margin-inline-end: 6px;
                display: flex;
                align-items: center;
            }
        }

        .tb-item a {
            display: flex;
            align-items: center;
            color: #333e48;
        }
    }
}

.home-top-info {
  margin: 0;

  &-links {
    height: 100%;
  }
}


.header-container{
    background-color: #ffffff;
    .header-wrapper {
        .header-top {
            padding-top: 20px;
            padding-bottom: 20px;

            .header-logo {
                display: none;
                margin-top: 5px;
                align-items: center;
                width: 200px;
            }

            .header-search-menu {
                flex: 1;
                //max-width: 65%;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}

@media (min-width: 992px) {
    .header-container {
        .header-wrapper {
            .header-top {
                .header-logo {
                    display: block;
                }
            }
        }
    }
}

.header-departments {
    box-sizing: border-box;
    margin: 0;
    padding: 9px 0 9px 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    .section-wrapper {
        position: relative;
    }

    .hd-list {
        display: flex;

        li {
            display: flex;
            max-width: 25%;
            width: auto;
            padding: 0 15px;
            align-items: center;
            font-size: 14px;
            color: #333e48;

            span {
                padding-left: 5px;
            }

        }
    }
}


//******************************************

.home-header {

    position: relative;
    will-change: transform;
    display: flex;
    justify-content: space-between;
    align-items: end;


    &-logo {

        color: #2a455d;
        height: 100px;

        img {
          height: 80px;
        }

        h1 {
          font-size: 50px;
          font-weight: 500;
          letter-spacing: 1px;
        }
    }

    &-menu {

      width: 100%;

      &-item {
        color: #2a455d;
        margin: 20px 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: end;

        &-icon {
          font-size: 30px;
          color: #2a455d;
          margin-right: 10px;
        }
      }

    }

}

@hack: true; @import "/app/src/asset/theme.less";