.cart-page .cart-right {
    flex: 0 0 100%;

    .cart-summary_footer {
        .cart-summary_total {
            font-weight: var(--font-bold);
            border-bottom: 1px solid var(--body-border-color);
            margin: 0 0 16px;
            padding: 0 0 var(--gutter-xxs);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .cart-summary_label {
        margin: 0;
        font-size: var(--heading-size-h4);
    }

    .cart-summary_price {
        font-size: var(--heading-size-h4);
        -webkit-margin-start: 14px;
        margin-inline-start: 14px;
    }

    .cart-summary_footer button {
        width: 100%;
        position: relative;
    }
}

@media (min-width: 768px) {
    .cart-page .cart-right {
        padding-top: 0;
        flex: 0 0 50%;
        -webkit-padding-start: var(--gutter-md);
        padding-inline-start: var(--gutter-md);
    }
}

@media (min-width: 1200px) {
    .cart-page .cart-right {
        flex: 0 0 41.666667%;
    }
}


@hack: true; @import "/app/src/asset/theme.less";