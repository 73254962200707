.product-props {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 16px;
}

.prop-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 18px;
}

.prop-key {
    width: 200px;
    padding-right: 20px;
    padding-bottom: 0;
    color: #333;
    font-weight: bold;
    //font-size: 12px;
    //color: #999;
}

.prop-value {
    flex: 1;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    color: var(--body-color-2);
}

.prop-value:first-letter, .prop-key:first-letter {
    text-transform: uppercase; /* Делаем заглавной только первую букву в предложении */
}

@media (min-width: 768px) {
    .prop-item {
        width: 50%;
    }
}

@media (min-width: 1020px) {
    .prop-item {
        width: 33.3%;
    }
}


@hack: true; @import "/app/src/asset/theme.less";