.cart-product {
    //display: flex;
    padding: 0;

    .cart-product-desc {
        display: flex;

        .product-img {
            display: block;
            flex: 0 0 90px;
            -webkit-margin-end: 15px;
            margin-inline-end: 15px;
        }

        .cart-product-container {
            padding-top: 0;
            flex: 1;
            -webkit-margin-end: 10px;
            margin-inline-end: 10px;

            div {
                padding: 0;
            }

            h1 {
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 400;
                text-align: justify;
            }

            .original {
                text-align: left;
            }
        }
    }
}

@media (min-width: 576px) {
    .product-img {
        flex: 0 0 116px;
    }
}

@media (min-width: 768px) {
    .product-img {
        flex: 0 0 90px;
        -webkit-margin-end: 20px;
        margin-inline-end: 20px;
    }

    .cart-product-container {
        -webkit-margin-end: 0;
        margin-inline-end: 0;
    }
}


@hack: true; @import "/app/src/asset/theme.less";