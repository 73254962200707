.product-detail {
    color: var(--body-color-2);
    .title {
        font-weight: var(--font-body-weight);
        font-size: 22px;
        margin: 0 0 13px;
        padding: 0;
        color: var(--body-color);
        font-family: var(--heading-font);
        text-align: justify;
    }

    .prop-title {
        display: none;
        max-width: 120px;
        min-width: 90px;
        margin: 0;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

@media (min-width: 768px) {
    .product-detail {
        .prop-title {
            display: block;
        }
    }
}


@hack: true; @import "/app/src/asset/theme.less";