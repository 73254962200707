@yellow-color: #ffdf00; /* Можно подстроить нужный оттенок жёлтого */

/* Кнопка внизу */
.footer-section {
  text-align: center;
  padding-bottom: 20px;

  .footer-section-button {
    background-color: @yellow-color;
    color: #666;
    border-color: @yellow-color;
  }

  .footer-section-button:hover {
    background-color: @yellow-color;
    color: #666;
    border-color: @yellow-color;
  }
}

@hack: true; @import "/app/src/asset/theme.less";