.sku-prop-table {
    --total-width: 100px;
    --price-width: 100px;
    --quantity-width: 130px;


    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;

    .sku-prop-table-head {
        div {
            font-size: 12px;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            text-align: center;
            //padding: 16px 15px;
        }

        .image {
            flex: 0 0 50px;
            max-width: 50px;
        }

        .prop-names {
            flex: 1;
            max-width: none;
            display: flex;
            justify-content: space-between;

            //div {
            //
            //    &:first-child {
            //        margin-left: 10px;
            //        text-align: left;
            //    }
            //}

            div {
                flex: 1;
                max-width: none;
            }
        }

        .quantity {
            flex: 0 0 var(--quantity-width);
            max-width: var(--quantity-width);
        }

        .price {
            flex: 0 0 var(--total-width);
            max-width: var(--total-width);
        }

        .total {
            flex: 0 0 var(--total-width);
            max-width: var(--total-width);
        }

        .remove {
            order: 6;
            flex: 0 0 50px;
            max-width: 50px;
        }
    }

    .sku-group-table-line {
        display: flex;
        margin: 10px 0;
        align-items: start;

        .image {
            flex: 0 0 50px;
            max-width: 50px;
        }

        .first-prop-name {
            flex: 1;
            text-align: left;
            margin-left: 8px;
        }

        .other {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }


    .sku-prop-table-line {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        & > div {
            font-size: 12px;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            text-align: center;
            //padding: 16px 15px;
        }

        .prop-names {
            flex: 1;
            max-width: none;
            justify-content: space-between;

            & > div {
                flex: 1;
                max-width: none;
            }
        }

        .quantity {
            flex: 0 0 var(--quantity-width);
            max-width: var(--quantity-width);
        }

        .price {
            flex: 0 0 var(--total-width);
            max-width: var(--total-width);

        }

        .total {
            flex: 0 0 var(--total-width);
            max-width: var(--total-width);
        }

        .remove {
            order: 6;
            flex: 0 0 50px;
            max-width: 50px;
        }

    }

    @media (min-width: 768px) {
        .sku-prop-table-head {
            display: flex;
            border-bottom: 1px solid #dddddd;
        }


    }
}

@hack: true; @import "/app/src/asset/theme.less";