.search-result-content {
    margin-top: 0px;

    .f-products-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .f-products-content_footer {
            margin: 15px 0;

            .ant-pagination {
                justify-content: right;
            }
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";