.input-group {
    --body-link-hover: #fed700;

    quantity-input {
        min-width: 95px;
        height: 34px;
        border: 1px solid var(--body-border-color);
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 0 var(--gutter-xs);
        -webkit-padding-end: 12px;
        padding-inline-end: 12px;
        max-width: none;

        input {
            border: none;
            font-size: var(--body-font-size);
            text-align: start;
            border-radius: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            padding: 0;
            order: 1;
            appearance: none;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            display: none; /* По умолчанию скрываем стрелки */
        }


        .qty-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;
        }

        .quantity-input-minus, .quantity-input-plus {
            width: 20px;
            flex: 0 0 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 3px;

            &:hover {
                svg {
                    color: var(--body-link-hover);
                    path {
                        fill: currentColor;
                    }
                }
            }
        }

        .quantity-input-plus {
            order: 2;
        }

        .quantity-input-minus {
            order: 3;
        }

    }

    @media (min-width: 1200px) {
        quantity-input {
            min-width: 110px;
        }
    }

    @media (min-width: 768px) {
        quantity-input {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@hack: true; @import "/app/src/asset/theme.less";