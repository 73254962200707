.cart-table {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;

    .cart-table-head {
        div{
            font-size: 14px;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            text-align: center;
            padding: 16px 15px;
        }

        .remove {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }

        .product {
            flex: 0 0 39.66666%;
            max-width: 39.66666%;
            text-align: start;
        }

        .price {
        }

        .quantity {
            flex: 0 0 18.66666%;
            max-width: 18.66666%;
        }

        .total {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }

    }

    .cart-table-line {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        &:not(:first-child) {
            border-top: 1px solid #dddddd;
        }

        & > div {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 10px 0;
            text-align: start;
        }

        .remove {
            order: 3;
            padding-top: 0;
            -webkit-padding-start: 120px;
            padding-inline-start: 120px;
        }

        .product {
            order: 2;
            display: flex;
            padding-bottom: 12px;
        }

        .total {
            //order: 5;
            text-align: start;
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }

    }

    @media (min-width: 576px) {
        .cart-table-line {
            .remove {
                -webkit-padding-start: 145px;
                padding-inline-start: 145px;
            }
        }
    }

    @media (min-width: 768px) {
        .cart-table-head {
            display: flex;
            border-bottom: 1px solid #dddddd;
        }

        .cart-table-line {
            div {
                text-align: center;
            }

            .remove {
                order: 1;
                flex: 0 0 8.333333%;
                max-width: 8.333333%;
                //padding-top: 24px;
                -webkit-padding-start: 0;
                padding-inline-start: 0;
            }

            .product {
                text-align: start;
                flex: 1;
                max-width: none;
                padding-bottom: 24px;
            }


        }
    }

}

@hack: true; @import "/app/src/asset/theme.less";