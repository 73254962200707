.cart-page .cart-left {
    flex: 0 0 100%;

    .shipping{
        .title {
            font-size: var(--heading-size-h4);
            font-weight: var(--heading-4-weight);
            margin-bottom: 16px;
        }

        .shipping-form {
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: space-between;

            .transport-company {
            }
        }

        .form-group {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: var(--gutter-sm);
        }
    }

    .cart-note {

        .cart-note_title {
            margin-bottom: 17px;

            & > span:first-child {
                font-size: var(--heading-size-h5);
                font-weight: var(--heading-5-weight);
            }
        }

        .note {
            max-width: 100%;
            min-width: 100%;
            min-height: 158px;
        }
    }
}

@media (min-width: 768px) {
    .cart-page .cart-left {
        flex: 0 0 50%;
        -webkit-padding-end: var(--gutter-md);
        padding-inline-end: var(--gutter-md);
    }
}

@media (min-width: 1200px) {
    .cart-page .cart-left {
        flex: 0 0 58.333333%;

        .shipping{
            .form-group {
                flex: 0 0 100%;
                max-width: 100%;
            }

        }
    }
}


@hack: true; @import "/app/src/asset/theme.less";