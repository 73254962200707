@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

*{
    scroll-behavior: smooth;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

body {
    word-wrap: break-word;
    background-color: #fff;

    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    //font-feature-settings: 'tnum';
    font-family: 'Open Sans', 'Exo 2', sans-serif;
}

@hack: true; @import "/app/src/asset/theme.less";