.product-card {
    //height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--gutter-xxs);
    background: var(--product-bg-color);
    width: 100%;

    .product-card-media {
        margin-bottom: var(--gutter-xs);
        position: relative;

        a {
            display: block;
        }

        a:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff3;
            width: 100%;
            height: 0;
            z-index: 1;
            //transition: all .35sease-in-out;
        }
    }

    img {
        object-fit: contain;
    }

    .content {
        display: none;
    }

    .goods-score {
        margin-bottom: 5px;
    }

    .product-card_original-title {
        color: var(--body-color-2);
        font-size: var(--product-card-vendor-fs);
        font-weight: var(--font-body-weight);
        margin-bottom: var(--gutter-xxs);
        font-family: var(--body-font);
        display: flex;
        align-items: center;

        a {
            color: inherit;
            font-size: inherit;
        }

        svg {
            font-size: 12px;
            padding-right: 5px;
            flex: 0 0 20px;
            align-self: start;
            //color: var(--main-color);
        }
    }

    .product-card_title {
        color: var(--product-card-title-cl);
        font-size: var(--product-card-title-fs);
        font-weight: var(--font-bold);
        margin-bottom: 7px;
        line-height: 1.4;
        text-align: justify;

        a {
            color: inherit;
        }
    }

    .short-description {
        display: none;
        margin-top: 23px;

        ul {
            li {
                list-style: none;
                color: var(--body-color-2);
                //margin-bottom: 3px;
                display: flex;
                align-items: center;

                span {
                    margin-right: 5px;
                }

                .icon {
                    svg {
                        margin-top: 2px;
                        width: 20px;
                    }
                }
            }
        }
    }

    .footer {
        margin-top: auto;
        background-color: inherit;

        .price-range-container {
            display: flex;
            justify-content: space-between;
            color: var(--body-color-2);
            overflow: hidden;
            font-size: 11px;

            .price-range {
                p {
                    margin: 3px 0;
                    line-height: 1.5;
                    text-align: left;
                    border: none;
                    min-width: 60px;
                }
            }
        }
    }
}

.balance-row-1 {
    .product-card_title {
        a {
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
        }
    }
}

.product-card_price-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-weight: var(--font-body-weight);
    color: var(--price-color);
    font-size: var(--product-card-price-fs);

    .price {
        -webkit-padding-end: 5px;
        padding-inline-end: 5px;
    }
}



.grid-1 {
    .product-card-container {
        flex: 0 0 100%;
        max-width: 100%;
        //height: 100%;

        &:not(:last-child) {
            margin-bottom: var(--gutter-sm);
            padding-bottom: var(--gutter-sm);
            border-bottom: 1px solid var(--body-border-color);
        }

        .product-card {
            padding: 0;
            background: transparent;
            display: block;

            .header {
                flex: 0 0 43%;
                width: 43%;
                float: left;
                -webkit-padding-end: var(--gutter-xs);
                padding-inline-end: var(--gutter-xs);

                .product-card-vendor-title {
                    display: none;
                }
            }

            .product-card-media {
                margin-bottom: 0;
            }

            .content {
                display: block;
                float: right;
                flex: 0 0 57%;
                width: 57%;
                -webkit-padding-start: var(--gutter-xs);
                padding-inline-start: var(--gutter-xs);
            }

            .footer {
                flex: 0 0 57%;
                width: 57%;
                float: right;
                margin-top: 0;
                -webkit-padding-start: var(--gutter-xs);
                padding-inline-start: var(--gutter-xs);
            }
        }
    }
}

@media (min-width: 480px) {
    .product-card {
        padding: var(--gutter-xs);
    }
}

@media (min-width: 576px) {
    .grid-1 {
        .product-card-container {
            .product-card {
                .header {
                    flex: 0 0 25%;
                    width: 25%;
                }

                .content {
                    flex: 0 0 75%;
                    width: 75%;
                }

                .footer {
                    flex: 0 0 75%;
                    width: 75%;

                }
            }
        }
    }
}


@media (min-width: 768px) {
    .grid-1 {
        .product-card-container {
            .product-card {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .header {
                    float: none;
                }

                .content {
                    flex: 0 0 50%;
                    width: 50%;
                    float: none;
                    -webkit-padding-end: var(--gutter-xs);
                    padding-inline-end: var(--gutter-xs);
                }

                .short-description {
                    display: block;
                }

                .footer {
                    float: none;
                    flex: 0 0 25%;
                    width: 25%;

                    .price-range-container {
                        font-size: 12px;
                    }
                }

                .product-card_price-action {
                    padding-bottom: var(--gutter-xxs);
                    margin-bottom: 11px;
                    border-bottom: 1px solid var(--body-border-color);
                }
            }
        }
    }
}


@media (min-width: 992px) {
    .grid-1 {
        .product-card-container {
            &:not(:last-child) {
                margin-bottom: var(--gutter-lg);
                padding-bottom: var(--gutter-lg);
            }

            .product-card {
                .footer {
                    .price-range-container {
                        display: flex;
                    }
                }

            }
        }
    }
}

@media (min-width: 1025px) {
    .product-card_price-action {
        border-bottom: 1px solid transparent;
        padding-bottom: 9px;
    }
}


@hack: true; @import "/app/src/asset/theme.less";