.header-mobile{
    .header-mobile-container {
        padding: 0!important;
    }

    .header-mobile_top {
        padding-right: 15px;
        padding-left: 15px;

        .icon {
            width: 27px;
            height: 27px;
        }
    }

    .header-mobile_bottom {
        padding-top: 12px;
    }

    padding: var(--gutter-xxs) 0 0 0;
    background: var(--header-bg-mobile-color);
    position: relative;
    box-shadow: 0 0 8px #c9c9c9bf;

    .header-logo {
        img {
            width: 55%;
        }
    }

    .header-icons {
        display: flex;
        -webkit-margin-start: auto;
        margin-inline-start: auto;

        ul {
            display: flex;

            li {
                display: flex;
                align-items: center;
                list-style: none;
                cursor: pointer;
                -webkit-margin-start: var(--gutter-xs);
                margin-inline-start: var(--gutter-xs);

                svg {
                    width: 24px;
                    height: 24px;
                    color: black;
                }
            }

            .mis-close {
                display: none;
            }
        }
    }

    .number {
        position: absolute;
        right: -5px;
        bottom: -10px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-color-mobile-number-icon);
        color: var(--color-mobile-number-icon);
        font-size: 12px;
        font-weight: var(--font-bold);
    }

    .header-search {
        //position: absolute;
        padding-top: 10px;
        left: 0;
        top: 100%;
        right: 0;
        -webkit-animation: mmfadeIn .35s ease-in-out;
        animation: mmfadeIn .35s ease-in-out;
        display: none;
        margin-top: 0;
        padding: var(--gutter-xxs) 0;
        background: white;
        box-shadow: 0 0 8px #c9c9c9bf;
        z-index: 9;

        &.active {
            display: block;
        }
    }

    .noborder {
        input {
            border: none !important;
        }
    }

    .fs-16 {
        input {
            font-size: 16px;
        }
    }

    .actions {
        display: flex;

        svg {
            margin-left: 5px;
        }
    }

}

@hack: true; @import "/app/src/asset/theme.less";