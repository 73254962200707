@yellow-color: #ffdf00; /* Можно подстроить нужный оттенок жёлтого */

.search-page {
  font-family: 'Roboto', sans-serif;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  padding-top: 0px;

  padding-bottom: 40px;
  align-items: center;
  width: 100%;
}

/* Шапка */
.header-section {
  background-color: @yellow-color;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  flex: 1;

  .header-column {

    padding: 10px 10px;
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-logo {
      width: 50%;
    }

    .header-title {
      color: #666;
      margin-bottom: 0px;
    }
  
    .header-subtitle {
      display: flex;
      flex-direction: column;
      padding-left: 0px;
      padding-right: 0px;
  
      .header-subtitle-text {
        color: #ffffff;
        font-size: 24px;
        margin-bottom: 0px;
        line-height: 1.4;
      }

    }
  }

}

/* Блок с карточками */
.features-section {
  background-color: #f5f5f5;
  padding: 0px 0px;
  flex: 1; /* Чтобы растягивался по высоте */


  .features-title {
    text-align: center;
    margin-bottom: 40px;
  }

  .feature-card {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    min-height: 300px;
    padding: 20px;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    .feature-icon {
      width: 70px;
      height: 70px;
      margin-bottom: 0px;
    }
  }
}

/* Кнопка внизу */
.footer-section {
  text-align: center;
  padding: 20px;

  .footer-section-button {
    background-color: @yellow-color;
    color: #666;
    border-color: @yellow-color;
  }

  .footer-section-button:hover {
    background-color: @yellow-color;
    color: #666;
    border-color: @yellow-color;
  }
}

@hack: true; @import "/app/src/asset/theme.less";