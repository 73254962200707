.container {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        //max-width:760px;
    }
}

@media (min-width: 1024px) {
    .container {
         //max-width:980px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width:1195px;
    }
}

@media (min-width: 1441px) {
    .container {
        max-width:1437px;
    }
}



@hack: true; @import "/app/src/asset/theme.less";