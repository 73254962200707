.profile-page {
    width: 100%;

    .customer-company-container {
        width: 100%;

        button {
            margin-top: 15px;
        }
    }

    .users-company-container {
        width: 100%;
    }
}

@hack: true; @import "/app/src/asset/theme.less";